@mixin tg-scroll(
  $widthHeight: 8px,
  $overflow: true,
  $overlay: true,
  $RTHUMB: 224,
  $GTHUMB: 224,
  $BTHUMB: 226,
  $RTHUMBHOVER: 181,
  $GTHUMBHOVER: 186,
  $BTHUMBHOVER: 208,
  $opacityBlur: 1,
  // 0.85,
  $opacityHover: 1 // 0.45
) {
  @if $overflow == true {
    overflow: auto;
  }

  @if $overlay == true {
    overflow: overlay !important;
  }

  &::-webkit-scrollbar {
    height: $widthHeight;
    width: $widthHeight;

    transition: all 1s;
  }

  &::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 6px rgba($RTRACK, $GTRACK, $BTRACK, 0.45) !important;
    -webkit-box-shadow: none;
    opacity: 0;
    background-color: transparent;
    transition: all 1s;
  }

  &::-webkit-scrollbar-thumb {
    transition: all 1s;
    background-color: rgba($RTHUMB, $GTHUMB, $BTHUMB, $opacityBlur);
    opacity: $opacityBlur;
    outline: 1px solid rgba($RTHUMB, $GTHUMB, $BTHUMB, 1);

    &:hover {
      transition: all 1s;
      opacity: $opacityHover !important;
      background-color: rgba($RTHUMBHOVER, $GTHUMBHOVER, $BTHUMBHOVER, $opacityHover);
    }
  }
}
