@import '../styles/color';

.mat-card,
.panel {
  padding: 16px;

  &.with-border-radius {
    border-radius: 8px;
  }

  &.blue {
    background-color: $blueWithOpacity;
  }

  &.ultra-light-silver {
    background-color: rgba(0, 0, 0, 0.01);
  }

  &.silver {
    background-color: rgba(181, 186, 208, 0.1);
  }

  &.silver2 {
    background-color: rgba(224, 224, 226, 0.1);
  }
}

.mobile {
  .panel.full-width {
    margin-top: -1.5rem;
    margin-left: -15px;
    margin-right: -15px;
    border-radius: 0;
  }
}

.ng-animating {
  .full-width {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.filter-panel {
  .mat-button-toggle-group {
    .mat-button-toggle-checked {
      background-color: #1e449c;
      color: #ffffff;
    }
  }
}

.mobile {
  .cdk-column-icon,
  .cdk-column-assigneeIcon {
    display: none;
  }

  .cdk-column-next-icon {
    padding-right: 0 !important;
  }
}
