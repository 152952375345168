.tg-icon-wrapper {
  width: 48px;
  height: 48px;
  align-items: center !important;
  justify-content: center !important;
  display: inline-flex;

  border-radius: 40px;
  background-color: #e6e8ee;

  .monogram {
    color: #1e449c;
    font-size: 18px;
  }

  &.small {
    width: 36px;
    height: 36px;

    .monogram {
      font-size: 12px;
    }
  }
}

.tg-icon {
  &:before {
    font-family: 'Apex';
  }

  &.circle {
    border-radius: 50%;
  }

  &.icon {
    &-f0e5:before {
      content: '\F0E5';
    }

    &-e961:before {
      content: '\E961';
    }

    &-e962:before {
      content: '\E962';
    }

    &-e95b:before {
      content: '\E95B';
    }

    &-e95c:before {
      content: '\E95C';
    }

    &-e9cb:before {
      content: '\E9CB';
    }

    &-e95c:before {
      content: '\E95C';
    }

    &-e964:before {
      content: '\E964';
    }

    &-e957:before {
      content: '\E957';
    }

    &-f2bc:before {
      content: '\F2BC';
    }

    &-f013:before {
      content: '\F013';
    }

    &-eaa3:before {
      content: '\EAA3';
    }

    &-f067:before {
      content: '\F067';
    }

    &-ea82:before {
      content: '\EA82';
    }

    &-e964:before {
      content: '\E964';
    }

    &-f09d:before {
      content: '\F09D';
    }

    &-f0c0:before {
      content: '\F0C0';
    }

    &-f059:before {
      content: '\F059';
    }

    &-e90e:before {
      content: '\E90E';
    }

    &-e90f:before {
      content: '\E90F';
    }

    &-e95f:before {
      content: '\E95F';
    }

    &-e900:before {
      content: '\E900';
    }

    &-e9db:before {
      content: '\E9DB';
    }

    &-e91e:before {
      content: '\E91E';
    }

    &-e966:before {
      content: '\E966';
    }

    &-ea15:before {
      content: '\EA15';
    }

    &-eaa1:before {
      content: '\EAA1';
    }

    &-e9b9:before {
      content: '\E9B9';
    }
  }
}
