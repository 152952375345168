@use '@angular/material' as mat;
@import './taskingo-theme-colors';

@include mat.core();

// Include your custom theme.
@include mat.all-component-themes($tg-app-theme);

.mat-form-field-required-marker {
  color: mat.get-color-from-palette($tg-app-warn) !important;
}
