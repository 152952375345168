.mat-table {
  .user-profile-picture {
    min-width: 34px !important;
    min-height: 34px !important;
    width: 34px !important;
    height: 34px !important;
    border-radius: 50% !important;
  }

  .user-monogram-icon-wrapper {
    cursor: default;
    width: 34px !important;
    height: 34px !important;
    border-radius: 50% !important;

    > .monogram {
      font-size: 14px !important;
    }
  }

  tbody {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }

  .table-column-data-icon {
    border-radius: 50%;
    background-color: #e6e8ee;
    padding: 8px 9px;
    font-size: 16px;
    color: #1e449c;
    width: 35px;
    height: 35px;
    display: inline-block;

    &::before {
      top: 1px;
      position: relative;
    }
  }

  td {
    .error-indicator {
      display: none;
    }

    &.error {
      position: relative;

      .error-indicator {
        display: block;
        position: absolute;
        color: #e62c2c;
        width: 16px;
        height: 16px;
        top: 15px;
        left: -25px;
        font-size: 16px;
        line-height: 16px;
        cursor: default;
      }
    }
  }

  .mat-column-icon {
    max-width: 30px;
    min-width: 30px;
    text-align: left;

    mat-icon {
      vertical-align: middle;
    }
  }

  .mat-column-next-icon {
    text-align: right;

    mat-icon {
      color: #1e449c !important;
    }
  }
}

.table-first-loading {
  color: rgba(0, 0, 0, 0.54);
  font-size: 18px;
}

.mobile {
  .mobile-next-icon {
    vertical-align: text-top;
  }
}
