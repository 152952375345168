@font-face {
  font-family: 'HelveticaNeue-Light';
  src: url('/assets/fonts/helvetica-neue-light/HelveticaNeue-Light.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/helvetica-neue-light/HelveticaNeue-Light.otf') format('opentype'),
    url('/assets/fonts/helvetica-neue-light/HelveticaNeue-Light.woff') format('woff'),
    url('/assets/fonts/helvetica-neue-light/HelveticaNeue-Light.ttf') format('truetype'),
    url('/assets/fonts/helvetica-neue-light/HelveticaNeue-Light.svg#HelveticaNeue-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}
