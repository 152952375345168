@use '@angular/material' as mat;
@import 'taskingo-theme-colors';

$warn: map-get($tg-app-theme, warn);

.mat-snack-bar-container {
  &.error {
    background-color: mat.get-color-from-palette($warn, 900, 0.8);
    color: white;
    font-family: HelveticaNeue-Bold, sans-serif !important;

    button {
      color: white;
      font-family: HelveticaNeue-Bold, sans-serif !important;
      background-color: rgba(130, 130, 130, 0.72);
    }
  }
}
