// https://medium.com/@tomastrajan/how-to-build-responsive-layouts-with-bootstrap-4-and-angular-6-cfbb108d797b
@import 'styles/styles-variables';
@import 'styles/taskingo-theme';
@import 'styles/reset';
@import 'styles/fonts/helvetica-neue/styles';
@import 'styles/fonts/helvetica-neue-light/styles';
@import 'styles/fonts/helvetica-neue-bold/styles';
@import 'styles/fonts/apex-icon';
//@import 'styles/fonts/helvetica-neue-ltpro-lt';
//@import 'styles/fonts/helvetica-neue-ltpro-roman';
//@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'app/modules/error-center/error';
@import 'styles/scroll';
@import 'styles/mat-snackbar';
@import 'styles/mat-table';
@import 'styles/mat-tooltip';
@import 'styles/custom-spaces';
@import 'styles/custom-sizing';
@import 'styles/user-mixins';
@import 'styles/tg-icon';
@import 'styles/tg-panels';
@import 'styles/tg-help-icon';
@import 'custom_bootstrap';

html,
body {
  height: auto;
  color: #232323;
  @include tg-scroll();
  background-color: white; /* #fafafa; */
}

html.cdk-global-scrollblock {
  overflow: hidden !important;
}

body {
  margin: 0;
}

*:not(mat-icon) {
  font-family: 'HelveticaNeue', sans-serif !important;
}

h1,
h2,
b,
strong {
  font-family: 'HelveticaNeue-Bold', sans-serif !important;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
}

h1,
h2 {
  color: #062056;
}

// fix
.mat-button {
  line-height: 36px !important;
}

// font fix
.mat-form-field {
  line-height: 1.5;

  .mat-form-field-prefix {
    margin-right: 4px;
  }
}

////////////// global
.sub-menu {
  .mat-toolbar:first-child,
  .mat-nav-list {
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 2 * 15px);
  }

  .mat-list-item {
    &:hover {
      background-color: #f1f0f0 !important;
    }

    &.active {
      background-color: #dddcdc;
    }
  }
}

.overflow-hidden {
  overflow: hidden;
}

// inputokban clear button
.clear-button {
  color: rgba(35, 35, 35, 0.4);
  cursor: pointer;
}

.tg-helvetica-bold {
  font-family: HelveticaNeue-Bold, sans-serif !important;
}

.tg-supplier-custom-option {
  line-height: 20px;
  font-size: 12px;
  border-bottom: #1e449c solid 1px;
}

.tg-menu-back-drop-class-closed {
  position: absolute;
  left: 49px;
  bottom: 63px;

  .tg-icon {
    font-size: 18px;
    color: #1e449c;
  }
}

.tg-menu-back-drop-class-opened {
  position: absolute;
  left: 210px;
  bottom: 63px;

  .tg-icon {
    font-size: 18px;
  }
}

.cursor-default {
  cursor: default !important;
}

textarea {
  @include tg-scroll();
  //height: auto !important;
}

.mat-column-assigneeIcon {
  position: relative;
}

.monogram-loader {
  width: 34px !important;
  height: 34px !important;

  svg {
    width: 34px !important;
    height: 34px !important;
  }
}

.flex-spacer {
  flex: 1 1 auto;
}

.mat-select-panel {
  @include tg-scroll();
}

.mat-checkbox-label {
  white-space: normal;
}

.cdk-overlay-container {
  z-index: 99999999;
}

.mobile {
  .mat-expansion-panel-header-title {
    word-break: break-word;
  }
}

.ios-ios,
.ios-chrome,
.ios-crios,
.macos-chrome,
.ios-safari,
.macos-safari {
  .mat-input-element:disabled {
    color: rgb(35, 35, 35) !important;
  }
}

.mobile,
.tablet {
  #jsd-widget {
    display: none !important;
  }

  .mat-expansion-panel-header {
    height: auto !important;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .mat-column-companyName,
  .mat-column-companyname,
  .mat-column-totalAmount {
    padding-left: 8px !important;
  }
}

.mobile {
  .table-scroll-wrapper {
    margin-left: -15px;
    margin-right: -15px;
    width: 100vw !important;
  }
}

.desktop {
  .table-scroll-wrapper {
    padding-left: 3px;
    padding-right: 3px;
    padding-bottom: 4px;
  }
}

body {
  &.not-auth,
  &.registration {
    #jsd-widget {
      bottom: auto !important;
      top: 0 !important;
      z-index: 9999 !important;
    }
  }
}

mat-icon[svgicon] {
  margin-top: -2px;
}

/* paginator select vertical position fix */
.mat-paginator-page-size-select .mat-form-field-infix {
  padding-top: 0 !important;
  border-top: 0 !important;
}

.mat-form-field input:-webkit-autofill + .mat-form-field-label-wrapper > label {
  transform: translateY(-1.34375em) scale(0.75) !important;
  width: 133.3333333333% !important;
}

a.link {
  font-weight: bold;
  color: #1e449c !important;
  text-decoration: none !important;

  &:visited {
    color: #1e449c !important;
    text-decoration: none !important;
  }
}
