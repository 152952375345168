.ms-16 {
  margin-left: 16px !important;
}

.me-16 {
  margin-right: 16px !important;
}

.ms-20 {
  margin-left: 20px;
}

.pb-16 {
  padding-bottom: 16px;
}

.mb-16 {
  margin-bottom: 16px !important;
}
