* {
  box-sizing: border-box;

  &:active,
  :focus {
    outline: none !important;
  }
}

label {
  margin-bottom: 0;
}

code {
  white-space: nowrap;
  border-radius: 10px;
  padding: 0 8px 1px 8px;
}
//
//.mat-menu-content {
//  mat-icon {
//    position: relative;
//    top: 2px;
//    left: 4px;
//  }
//}
