@font-face {
  font-family: 'HelveticaNeue';
  src: url('/assets/fonts/helvetica-neue/HelveticaNeue.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/helvetica-neue/HelveticaNeue.otf') format('opentype'),
    url('/assets/fonts/helvetica-neue/HelveticaNeue.woff') format('woff'),
    url('/assets/fonts/helvetica-neue/HelveticaNeue.ttf') format('truetype'),
    url('/assets/fonts/helvetica-neue/HelveticaNeue.svg#HelveticaNeue') format('svg');
  font-weight: normal;
  font-style: normal;
}
