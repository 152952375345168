@font-face {
  font-family: 'HelveticaNeue-Bold';
  src: url('/assets/fonts/helvetica-neue-bold/HelveticaNeue-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/helvetica-neue-bold/HelveticaNeue-Bold.otf') format('opentype'),
    url('/assets/fonts/helvetica-neue-bold/HelveticaNeue-Bold.woff') format('woff'),
    url('/assets/fonts/helvetica-neue-bold/HelveticaNeue-Bold.ttf') format('truetype'),
    url('/assets/fonts/helvetica-neue-bold/HelveticaNeue-Bold.svg#HelveticaNeue-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
